.timer-wrapper {
  > div {
    width: 4rem !important;
    height: 4rem !important;

    svg {
      width: 4rem !important;
      height: 4rem !important;

      path {
        stroke-width: 0.5rem !important;

        &:first-of-type {
          @apply tw-stroke-countdown-trail;
        }

        &:last-of-type {
          @apply tw-stroke-countdown-base;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    > div {
      width: 6rem !important;
      height: 6rem !important;

      svg {
        width: 6rem !important;
        height: 6rem !important;

        path {
          stroke-width: 0.675rem !important;
        }
      }
    }
  }
}