@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        --bg-color-layout: #08090C;
        --bg-color-layout-accent: #262b45;
        --bg-color-layout-accent-alt: #262b45;
        --bg-color-error: #F24A4A;
        --bg-color-success: #7AC261;
        --bg-color-info: #578CDD;
        --bg-color-component: #171924;
        --bg-color-component-accent: #AF93FF;
        --bg-color-payment-layout: #202333;
        --bg-color-payment-layout-out: #131520;
        --bg-color-card-digits-layout: #131520;
        --bg-color-status-icon: #171924;
        --bg-color-status-error-icon: #171924;

        --bg-color-primary-input: #131520;
        --bg-color-secondary-input: #3C4159;

        --bg-color-primary-button: #AF93FF;
        --bg-color-primary-button-hover: #9069ff;
        --bg-color-primary-button-active: #9680d0;

        --bg-color-secondary-button: #13141F;
        --bg-color-secondary-button-hover: #0D0E15;
        --bg-color-secondary-button-active: #42434B;

        --bg-color-tertiary-button: #202333;
        --bg-color-tertiary-button-hover: #161823;
        --bg-color-tertiary-button-active: #4C4F5B;

        --bg-color-success-button: #79c260;
        --bg-color-success-button-hover: #558743;
        --bg-color-success-button-active: #80b96c;

        --bg-color-error-button: #301616;
        --bg-color-error-button-hover: #A93333;
        --bg-color-error-button-active: #4d2323;

        --bg-color-info-button: #578CDD;
        --bg-color-info-button-hover: #3C629A;
        --bg-color-info-button-active: #78A3E3;

        --bg-color-disabled-button: rgba(255, 255, 255, 0.12);

        --color-primary: #fff;
        --color-secondary: #6b6e80;
        --color-alternative: #000;
        --color-layout-accent: #4C5792;
        --color-component: #171924;
        --color-component-accent: #303348;

        --color-primary-button: #FFF;
        --color-secondary-button: #fff;
        --color-tertiary-button: #fff;
        --color-success-button: #000;
        --color-error-button: #fff;
        --color-info-button: #fff;
        --color-disabled-button: rgba(0, 0, 0, 0.4);
        --color-copy-button: #6b6e80;

        --color-primary-input: #fff;
        --color-secondary-input: #fff;
        --color-countdown: #fff;

        --stroke-color-base: #AF93FF;
        --stroke-color-trail: #131520;

        --border-color-component-accent: #AF93FF;
        --border-color-layout-accent: #262b45;
        --border-color-component: #171924;
        --border-color-steps: #171924;
        --border-color-error-button: rgb(169, 51, 51);

        --box-shadow-component: 0 -16px 0 0 var(--bg-color-component);

        --card-background: #111;
        --card-stroke: #333;
        --card-accent: #f7f7f7;
        --card-logo: #fff;
        --card-font: #fff;
        --card-code-font: #c5c5c5;
        --card-chip: #fff;
        --card-chip-stroke: #fff;

        --bank-logo-font: #c5c5c5;
        --bank-logo-font-hover: #c5c5c5;

        --copy-icon-color: #626A9E;
        --exclamation-icon-color: #626A9E;
        --status-icon-color: #7ac261;
        --status-error-icon-color: #f24a4a;
    }

    html[data-theme="payforu"] {
        --bg-color-component: #151619;
        --bg-color-component-accent: #37AC35;

        --bg-color-primary-button: #37AC35;
        --bg-color-primary-button-hover: #7AC261;
        --bg-color-primary-button-active: #94CE80;

        --bg-color-secondary-button: #202333;
        --bg-color-secondary-button-hover: #161823;
        --bg-color-secondary-button-active: #4C4F5B;

        --bg-color-success-button: #37AC35;
        --bg-color-success-button-hover: #7AC261;
        --bg-color-success-button-active: #94CE80;

        --color-alternative: #fff;

        --stroke-color-base: #37AC35;

        --border-color-component-accent: #37AC35;
    }

    html[data-theme="light"] {
        --bg-color-layout: #f6f6f6;
        --bg-color-layout-accent: #fcfcfc;
        --bg-color-layout-accent-alt: #af93ff;
        --bg-color-component: #fcfcfc;
        --bg-color-payment-layout: #f0f0f0;
        --bg-color-payment-layout-out: #f6f6f6;
        --bg-color-card-digits-layout: #e0e0e0;
        --bg-color-status-icon: #7ac261;
        --bg-color-status-error-icon: #f24a4a;

        --bg-color-primary-input: #fff;
        --bg-color-secondary-input: #fff;

        --bg-color-secondary-button: #af93ff;
        --bg-color-secondary-button-hover: #9069ff;
        --bg-color-secondary-button-active: #9680d0;

        --bg-color-tertiary-button: #af93ff;
        --bg-color-tertiary-button-hover: #9069ff;
        --bg-color-tertiary-button-active: #9680d0;

        --bg-color-error-button: #c23535;
        --bg-color-error-button-hover: #aa2525;

        --bg-color-disabled-button: rgba(185, 185, 185, 0.12);

        --color-primary: #595959;
        --color-secondary: #A5A5A5;
        --color-alternative: #fff;

        --color-primary-input: #000;
        --color-secondary-input: #333;
        --color-countdown: #000;
        --color-copy-button: #fff;

        --color-component: #fff;
        --color-layout-accent: #fff;
        --color-component-accent: #aaa;
        --color-tertiary-button: #fff;

        --stroke-color-trail: #e7dfff;

        --border-color-component: #fff;
        --border-color-layout-accent: #E4E4E4;
        --border-color-steps: #c3c3c3;

        --card-background: #fff;
        --card-stroke: #d7d7d7;
        --card-accent: #fff;
        --card-logo: #333;
        --card-font: #7c7c7c;
        --card-chip: #e4e4e4;
        --card-chip-stroke: #9c9c9c;

        --copy-icon-color: #fff;
        --exclamation-icon-color: #fff;
        --status-icon-color: #fff;
        --status-error-icon-color: #fff;
    }
}
