.card-background {
  fill: var(--card-background);
};

.card-accent {
  fill: var(--card-accent);
};

.card-stroke {
  stroke: var(--card-stroke);
};

.card-logo {
  fill: var(--card-logo);
}

.card-font {
  fill: var(--card-font);
}

.card-code-font {
  fill: var(--card-code-font);
}

.card-chip {
  fill: var(--card-chip);
}

.card-chip-stroke {
  stroke: var(--card-chip-stroke);
}

.card-chip-inner {
  fill: var(--card-chip-stroke);
}

.bank-logo-font {
  fill: var(--bank-logo-font);
}